<template>
        <div style="padding: 15px">
            <a-tabs type="card" @change="onChange">
                <a-tab-pane :key="index" :tab="item" v-for="(item,index) in tabs"></a-tab-pane>
                <a-button slot="tabBarExtraContent" type="primary" @click="onTask">
                    新建任务
                </a-button>
            </a-tabs>
            <div style="margin-bottom: 20px;padding: 10px;display: flex;flex-wrap: wrap;">
                <div style="height: 200px;width: 25%;display: flex;padding: 5px" v-for="item in 20">
                    <div style="display: flex;
    flex-direction: column;
    justify-content: space-around;    margin: 5px;
    border: 1px solid rgb(131 122 122);
    width: 100%;padding: 20px;    border-left-width: 6px;
    border-left-color: red;
">
                        <div>
                            九月份未接通知重新跟踪
                        </div>
                        <div>  <a-progress :percent="50" status="active" /></div>
                    </div>
                </div>
            </div>
            <a-pagination
                    show-size-changer
                    :default-current="3"
                    :total="500"
                    @showSizeChange="onShowSizeChange"
            />
            <FormModel :config="config" :submit="onsubmit"></FormModel>
        </div>

</template>

<script>
    import FormModel from "../../components/Customer/FormModel";
export default {
    components: {
      FormModel
    },
    data() {
        return {
            pageSize: 20,
            current: 4,
            tabs:['全部（50）','我执行的（5）','我分配的（5）','我关注的（5）','即将逾期（5）','已逾期（5）','未完成（5）','已完成（5）'],
            config:{
                visible:false,
                title:'新建任务',
                form:[
                    {type:'date',model:'date',placeholder:'请选择',label:'时间',value:'2022-11-11'},
                    {type:'input',model:'taskName',placeholder:'请选择',label:'任务名称',value:''},
                    {type:'select',model:'select',placeholder:'请选择',label:'动态类型',value:'',list:[
                            {label:'全部',value:'1'},
                            {label:'我关注的',value:'2'},
                        ]},
                    {type:'select',model:'selects',placeholder:'请选择',label:'客户',value:'',list:[
                            {label:'全部',value:'1'},
                            {label:'我关注的',value:'2'},
                        ]},
                ]

            }
        };
    },
    watch: {
        pageSize(val) {
            console.log('pageSize', val);
        },
        current(val) {
            console.log('current', val);
        },
    },
    methods: {

        onShowSizeChange(current, pageSize) {
            console.log(current, pageSize);
        },
        onChange(key) {
            this.key = key;
        },
        onTask(){
            let that = this;
            that.config.visible = true;
        },
        onsubmit(config){
            console.log(config.form)
            return true;
        }

    },
}
</script>

<style scoped>

</style>