<template>
    <div style="background-color: white">
        <div style="position: absolute;z-index: 99;right:1px;top: 400px">
            <a-affix :offset-top="top" >
                <div class="follow-item" @click="follow" style="cursor: pointer">跟踪建议
                    <a-badge count="5">
                        <a href="#" class="head-example" />
                    </a-badge>
                </div>
            </a-affix>
        </div>
        <Table :config="config" :changeTab="changeTab" :buttonMethod="onButtonMethod">
            <div slot="form">
                <component v-bind:is="config.formConfig.component"></component>
            </div>
        </Table>
        <FormModel :config="modelConfig" :submit="onsubmit"></FormModel>
        <AutoShare :config="autoConfig"></AutoShare>
        <FollowSuggest :config="FollowConfig"></FollowSuggest>
    </div>
</template>
<script>
    import Table from "../../components/Customer/Table"
    import AutoShare from "./AutoShare";
    import Dynamic from "./Dynamic";
    import Search from "../../components/Customer/Search";
    import FollowSuggest from "./FollowSuggest";
    import FollowRecord from "./FollowRecord";
    import TaskList from "./TaskList";
    import TargetManage from "./TargetManage";
    import FormModel from "../../components/Customer/FormModel";
    export default {
        components: {
            FormModel,
            AutoShare,
            Table,
            Dynamic,
            Search,
            FollowSuggest,
            FollowRecord,
            TaskList,
            TargetManage
        },
        data() {
            return {
                config: {
                    tabsConfig:['客户','公共池','客户动态','跟踪记录','任务列表','产品销售','合同管理','目标管理','积分管理'],//指定tab切换标签
                    searchConfig: {
                        searchFields:[],
                        buttonFields:[]
                    },
                    tableConfig:{
                        columns:[],
                        data:[],
                    },
                    formConfig:{
                        component:'FollowSuggest',
                    },
                    pageConfig:{


                    },
                },
                top: 10,
                earth:require('./../../../src/assets/images/earth.png'),
                modelConfig:{
                    visible:false,
                    title:'新建任务',
                    form:[
                        {type:'date',model:'date',placeholder:'请选择',label:'时间',value:'2022-11-11'},
                        {type:'input',model:'taskName',placeholder:'请选择',label:'任务名称',value:''},
                        {type:'select',model:'select',placeholder:'请选择',label:'动态类型',value:'',list:[
                                {label:'全部',value:'1'},
                                {label:'我关注的',value:'2'},
                            ]},
                        {type:'select',model:'selects',placeholder:'请选择',label:'客户',value:'',list:[
                                {label:'全部',value:'1'},
                                {label:'我关注的',value:'2'},
                            ]},
                    ]

                },
                autoConfig: {
                    visible:false,
                },
                FollowConfig: {
                    visible:false,
                },
            };
        },
        mounted() {
            let that = this;
            that.changeTab(0)
        },
        methods: {
            //切换模块
            changeTab(key){
                let that = this;
                console.log('点击'+that.config.tabsConfig[key]+'模块'+'+索引:'+key)
                switch (key) {//方法名
                    case 0:
                        for (let i = 0; i < 20; i++) {
                            that.config.tableConfig.data.push({
                                key: i,
                                name: `王乐 ${i}`,
                                age: 32,
                                address: `London Park no. ${i}`,
                            });
                        }
                        that.config.tableConfig.columns  = [
                            { title: '客户名称', width: 150, dataIndex: 'name', key: 'name', fixed: 'left',align:'center',scopedSlots: { customRender: 'name' } },
                            { title: '生日', width: 100, dataIndex: 'age', key: 'age', fixed: 'left',align:'center' },
                            { title: '联系电话', dataIndex: 'address', key: '1', width: 150 ,align:'center'},
                            { title: '状态', dataIndex: 'address', key: '2', width: 150 ,align:'center'},
                            { title: '意向度', dataIndex: 'address', key: '3', width: 150 ,align:'center'},
                            { title: '评分', dataIndex: 'address', key: '4', width: 150,align:'center' },
                            { title: '标签', dataIndex: 'address', key: '5', width: 150 ,align:'center'},
                            { title: '来源', dataIndex: 'address', key: '6', width: 150,align:'center' },
                            { title: '跟踪记录', dataIndex: 'address', key: '7', width: 150 ,align:'center'},
                            { title: '跟踪结果', dataIndex: 'address', key: '8',align:'center' },
                            { title: '最后跟踪时间', dataIndex: 'address', key: '9' ,align:'center'},
                            { title: '未跟踪天数', dataIndex: 'address', key: '10' ,align:'center'},
                            { title: '关注', dataIndex: 'address', key: '11' ,align:'center'},
                            {
                                title: '操作',
                                key: 'operation',
                                fixed: 'right',
                                width: 100,
                                scopedSlots: { customRender: 'action' },
                            },]
                        that.config.searchConfig.placeholder = '请输入客户名称或电话'
                        that.config.searchConfig.searchFields=[]
                        that.config.searchConfig.buttonFields=[
                            {label:'高级筛选',type:'primary',method:'screen',icon:'',background_color:'rgb(251 82 82)'},
                            {label:'新建客户',type:'primary',method:'add',icon:'',background_color:'#56AF3D'},
                            {label:'创建任务',type:'primary',method:'detail',icon:'',background_color:'#56AF3D'},
                            {label:'更换客服',type:'primary',method:'changeService',icon:'swap',background_color:'rgb(163 143 24)'},
                            {label:'更换顾问',type:'primary',method:'changeAdviser',icon:'swap',background_color:'#FD6D3A'},
                            {label:'放入公共池',type:'primary',method:'publicPool',icon:'database',background_color:'rgb(37 165 209)'},
                            {label:'自动分配',type:'primary',method:'autoShare',icon:'share-alt',background_color:'rgb(163 143 24)'},
                            {label:'添加任务',type:'primary',method:'receive3',icon:'plus',background_color:'#FD6D3A'},
                            {label:'发送消息',type:'primary',method:'sendMessage',icon:'message',background_color:'#56AF3D'},
                            {label:'导入客户',type:'primary',method:'importCustomer',icon:'',background_color:'#FD6D3A'},
                        ]
                        that.config.formConfig.component = ''
                        that.config.pageConfig = {
                                current: 1,
                                pageSize: 5,

                                showSizeChanger: true,

                                total: 100,

                                pageSizeOptions: ['5', '10', '20', '30', '40', '100'],

                                showTotal: (total) => `共 ${total} 条数据`,

                                onShowSizeChange: this.pageSizeChange,

                                onChange: this.pageChange,

                         }
                        break;
                    case 1:
                        for (let i = 0; i < 100; i++) {
                            that.config.tableConfig.data.push({
                                key: i,
                                name: `Edrward ${i}`,
                                age: 32,
                                address: `London Park no. ${i}`,
                            });
                        }
                        this.config.tableConfig.columns = [
                            { title: 'Full Name1', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
                            { title: 'Age', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
                            { title: 'Column 1', dataIndex: 'address', key: '1' },
                            { title: 'Column 2', dataIndex: 'address', key: '2' },
                            { title: 'Column 3', dataIndex: 'address', key: '3' },
                            { title: 'Column 4', dataIndex: 'address', key: '4' },
                            {
                                title: 'Action',
                                key: 'operation',
                                fixed: 'right',
                                width: 100,
                                scopedSlots: { customRender: 'action' },
                            },
                        ];
                        that.config.searchConfig.searchFields = [
                            {type:'input',model:'name',placeholder:'输入客户',label:'客户',value:''},
                            {type:'select',model:'public',placeholder:'请选择',label:'公共池',value:'',list:[
                                    {label:'全部',value:'1'},
                                    {label:'我关注的',value:'2'},
                                ]},
                            {type:'select',model:'scene',placeholder:'请选择',label:'场景',value:'',list:[
                                    {label:'全部',value:'1'},
                                    {label:'我关注的',value:'2'},
                                ]},
                        ]
                        that.config.searchConfig.buttonFields=[
                            {label:'高级筛选',type:'primary',method:'screen',icon:'',background_color:'rgb(251 82 82)'},
                            {label:'领取',type:'',method:'receive',icon:'plus-square',background_color:'#56AF3D'},
                            {label:'分配',type:'',method:'receive',icon:'swap',background_color:'rgb(163 143 24)'},
                            {label:'转移',type:'',method:'transfer',icon:'share-alt',background_color:'#FD6D3A'},
                        ]
                        break;
                    case 2:
                        that.config.tableConfig.columns = [];
                        that.config.tableConfig.data = [];
                        // 菜单按钮
                        that.config.searchConfig.buttonFields=[]
                        that.config.searchConfig.searchFields=[
                            {type:'date',model:'date',placeholder:'请选择',label:'时间',value:'2022-11-11'},
                            {type:'select',model:'select',placeholder:'请选择',label:'动态类型',value:'',list:[
                                    {label:'全部',value:'1'},
                                    {label:'我关注的',value:'2'},
                                ]},
                            {type:'select',model:'selects',placeholder:'请选择',label:'客户',value:'',list:[
                                    {label:'全部',value:'1'},
                                    {label:'我关注的',value:'2'},
                                ]},
                            {type:'date',model:'dates',placeholder:'请选择',label:'',value:''},
                        ]
                        that.config.formConfig.component = 'Dynamic';
                        break;
                    case 3:
                        that.config.tableConfig.data = [];
                        that.config.tableConfig.columns = [];
                        that.config.searchConfig.searchFields=[]
                        that.config.searchConfig.buttonFields=[]
                        that.config.formConfig.component = 'FollowRecord';
                        break;
                    case 4:
                        that.config.tableConfig.data = [];
                        that.config.tableConfig.columns = [];
                        that.config.searchConfig.searchFields=[]
                        that.config.searchConfig.buttonFields=[]
                        that.config.formConfig.component = 'TaskList';
                        break;
                    case 7:
                        that.config.tableConfig.data = [];
                        that.config.tableConfig.columns = [];
                        that.config.searchConfig.searchFields=[]
                        that.config.searchConfig.buttonFields=[]
                        that.config.formConfig.component = 'TargetManage';
                        break;
                }
            },
            //按钮方法
            onButtonMethod(method,array){
                let that = this;
                console.log('点击执行'+method+'方法')
                console.log('其他参数'+array+'方法')
                switch (method) {//方法名
                    case 'search':
                        that.onSearch(array)
                        break;
                    case 'add':
                        that.add();
                        break;
                    case 'changeService':
                        that.changeService();
                        break;
                    case 'changeAdviser':
                        that.changeAdviser()
                        break;
                    case 'publicPool':
                        that.publicPool()
                        break;
                    case 'autoShare':
                        that.autoShare()
                        break
                    case 'sendMessage':
                        that.sendMessage()
                        break;
                    case 'transfer':
                        break;
                    case 'detail':
                        that.detail()
                        break;
                    case 'importCustomer':
                        that.importCustomer()
                        break;
                    default:
                        that.method;

                }
            },
            detail(){
                this.$router.push({ path: '/customerDetail', query: { Id: 8 }})
            },
            onsubmit(config){
                console.log('表单参数'+config.form)
                console.log('执行方法：'+config.method)
                return true;//回调参数回去
            },
            //搜索方法
            onSearch(param){
                console.log('点击查询')
                console.log(param)
            },
            //导入客户
            importCustomer(){
                this.$router.push({ path: '/importCustomer', query: { Id: 8 }})
            },
            add(){
                this.$router.push({ path: '/addCustomer', query: { Id: 8 }})
            },
            //跟踪建议
            follow(){
                 let that = this;
                 that.FollowConfig.visible = true
            },
            //客户地图
            CustomerMap(){
                this.$router.push({ path: '/CustomerMap', query: { Id: 8 }})
            },
            //自动分配
            autoShare(){
                let that = this;
                that.autoConfig.visible = true
            },
            //发送消息
            sendMessage(){
                let that = this;
                that.modelConfig.visible=true
                that.modelConfig.title='消息类型'
                that.modelConfig.method='onSendMessage'
                that.modelConfig.form=[
                    {type:'select',model:'select',placeholder:'选择员工',label:'消息类型',value:'',list:[{label:'员工1',value:'1'}, {label:'员工2',value:'2'},]},
                ]
            },
            //放入公共池
            publicPool(){
                let that = this;
                that.modelConfig.visible=true
                that.modelConfig.title='放入公共池'
                that.modelConfig.method='onPublicPool'
                that.modelConfig.form=[
                    {type:'select',model:'select',placeholder:'选择员工',label:'公共池',value:'',list:[{label:'员工1',value:'1'}, {label:'员工2',value:'2'},]},
                    {type:'textarea',model:'taskName',placeholder:'输入原因',label:'原因',value:''},

                ]
            },
            //更换顾问
            changeAdviser(){
                let that = this;
                that.modelConfig.visible=true
                that.modelConfig.title='更换顾问'
                that.modelConfig.method='onChangeAdviser'
                that.modelConfig.form=[
                    {type:'select',model:'select',placeholder:'请选择',label:'变更顾问为',value:'',list:[{label:'顾问1',value:'1'}, {label:'顾问2',value:'2'},]},
                    {type:'checkbox',model:'taskName',placeholder:'请选择',label:'关联到任务',value:''},
                    {type:'select',model:'select',placeholder:'请选择任务',label:'任务',value:'',list:[{label:'任务1',value:'1'}, {label:'任务2',value:'2'},]},
                    {type:'input',model:'taskName',placeholder:'请输入备注',label:'备注',value:''},

                ]
            },
            //更换客服
            changeService(){
                 let that = this;
                 that.modelConfig.visible=true
                 that.modelConfig.title='更换客服'
                 that.modelConfig.method='onChangeService'
                 that.modelConfig.form=[
                     {type:'select',model:'select',placeholder:'请选择',label:'变更客服为',value:'',list:[{label:'客服1',value:'1'}, {label:'客服2',value:'2'},]},
                     {type:'input',model:'taskName',placeholder:'请输入备注',label:'备注',value:''},
                 ]
            },
            pageSizeChange(val, pageNum) {

                console.log(val, pageNum)

            },

            pageChange(page, val) {
                console.log(page, val)
            },

        },
    };
</script>
<style scoped>
    .follow-item{
        width: 27px;color: #fff;
        background-color: #1890ff;
        border-color: #1890ff;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
        -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
        box-shadow: 0 2px 0 rgb(0 0 0 / 5%);text-align: center
    }
</style>
