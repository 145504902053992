<template>
    <div style="background-color: white">
        <Table :config="config" :changeTab="changeTab">
            <div slot="form">
<!--                <component v-bind:is="config.formConfig.component"></component>-->
            </div>
        </Table>
    </div>
</template>
<script>
    import Table from "../../components/Customer/Table"
    import TaskList from "./TaskList";
    export default {
        components: {
            Table,
            TaskList
        },
        data() {
            return {
                config: {
                    tabsConfig:['目标进度','目标值设置','目标规则'],//指定tab切换标签
                    searchConfig: {
                        searchFields:[],
                        buttonFields:[
                            {label:'新建目标',type:'primary',method:'add',icon:'',background_color:'#56AF3D'},
                            {label:'创建任务',type:'primary',method:'receive1',icon:'',background_color:'#56AF3D'},
                            {label:'更换客服',type:'primary',method:'changeService',icon:'swap',background_color:'rgb(163 143 24)'},
                        ]
                    },
                    tableConfig:{
                        columns:[],
                        data:[],
                    },
                    formConfig:{
                        component:'',
                    }
                },
            };
        },
        mounted() {
            let that  = this;
            that.changeTab(0)
        },
        methods: {
            //切换模块
            changeTab(key){
                let that = this;
                console.log(that.config.tabsConfig[key]+'模块'+'+索引:'+key)
                switch (key) {//方法名
                    case 0:
                        const axioss = require('axios');
                        axioss.get('https://customer.com/api/student')
                            .then(function (response) {
                                // 处理成功情况
                                if(response.data.code==200){
                                    console.log(response.data.data)
                                    that.config.tableConfig.data = response.data.data.list;
                                    that.config.tableConfig.columns  = [
                                        {
                                            title: 'Name',
                                            dataIndex: 'name',
                                            scopedSlots: { customRender: 'name' },
                                        },
                                        {
                                            title: 'Age',
                                            dataIndex: 'age',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address2',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address3',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address4',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address5',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address6',
                                        },
                                    ];

                                }
                            })
                            .catch(function (error) {
                                // 处理错误情况
                                console.log(error);
                            })
                            .then(function () {
                                // 总是会执行
                            });
                        that.config.searchConfig.searchFields=[
                            {type:'select',model:'public',placeholder:'请选择',label:'目标',value:'',list:[
                                    {label:'顾问业绩目标',value:'1'},
                                    {label:'我关注的',value:'2'},
                                ]},
                            {type:'select',model:'scene',placeholder:'请选择',label:'财年',value:'',list:[
                                    {label:'2022年',value:'1'},
                                    {label:'2021年',value:'2'},
                                ]},
                        ]
                        that.config.searchConfig.buttonFields=[]
                        that.config.formConfig.component = ''
                        break;
                    case 1:
                        that.config.columns = [
                            { title: 'Full Name1', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
                            { title: 'Age', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
                            { title: 'Column 1', dataIndex: 'address', key: '1' },
                            { title: 'Column 2', dataIndex: 'address1', key: '2' },
                            { title: 'Column 3', dataIndex: 'address2', key: '3' },
                            { title: 'Column 4', dataIndex: 'address3', key: '4' },
                            {
                                title: 'Action',
                                key: 'operation',
                                fixed: 'right',
                                width: 100,
                                scopedSlots: { customRender: 'action' },
                            },
                        ];
                        that.config.searchConfig.searchFields = [
                            {type:'select',model:'public',placeholder:'请选择',label:'公共池',value:'',list:[
                                    {label:'全部',value:'1'},
                                    {label:'我关注的',value:'2'},
                                ]},
                            {type:'select',model:'scene',placeholder:'请选择',label:'场景',value:'',list:[
                                    {label:'全部',value:'1'},
                                    {label:'我关注的',value:'2'},
                                ]},
                        ]
                        that.config.searchConfig.buttonFields=[
                            {label:'领取',type:'',method:'receive',icon:'plus-square',background_color:'#56AF3D'},
                            {label:'分配',type:'',method:'receive',icon:'swap',background_color:'rgb(163 143 24)'},
                            {label:'转移',type:'',method:'transfer',icon:'share-alt',background_color:'#FD6D3A'},
                        ]
                        break;
                    case 2:
                        const axios = require('axios');
                        axios.get('https://customer.com/api/student')
                            .then(function (response) {
                                // 处理成功情况
                                if(response.data.code==200){
                                    console.log(response.data.data)
                                    that.config.tableConfig.data = response.data.data.list;
                                    that.config.tableConfig.columns  = [
                                        {
                                            title: 'Name',
                                            dataIndex: 'name',
                                            scopedSlots: { customRender: 'name' },
                                        },
                                        {
                                            title: 'Age',
                                            dataIndex: 'age',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address2',
                                        },
                                        {
                                            title: 'Address3',
                                            dataIndex: 'address4',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address5',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address6',
                                        },
                                        {
                                            title: 'Address',
                                            dataIndex: 'address76',
                                        },
                                    ];

                                }
                            })
                            .catch(function (error) {
                                // 处理错误情况
                                console.log(error);
                            })
                            .then(function () {
                                // 总是会执行
                            });
                        that.config.searchConfig.searchFields=[
                        ]
                        that.config.searchConfig.buttonFields=[
                            {label:'新建规则',type:'primary',method:'add',icon:'',background_color:'#56AF3D'},
                            {label:'新建目标',type:'primary',method:'add',icon:'',background_color:'#FD6D3A'},
                            {label:'创建任务',type:'primary',method:'receive1',icon:'',background_color:'#56AF3D'},
                            {label:'更换客服',type:'primary',method:'changeService',icon:'swap',background_color:'rgb(163 143 24)'},
                        ]
                        that.config.formConfig.component = ''
                        break;
                }
            },
            //按钮方法
            onButtonMethod(method){
                let that = this;
                console.log('点击执行'+method+'方法')
                switch (method) {//方法名
                    case 'add':
                        that.add();
                        break;
                    case 'changeService':
                        that.changeService();
                        break;
                    default:
                        that.method;

                }
            },
            CustomerMap(){
                this.$router.push({ path: '/CustomerMap', query: { Id: 8 }})
            },
            //搜索方法
            onSearch(param){
                console.log(param)
            },
            close(visible){
                let that = this;
                that.visible = visible;
            },
            cancel(visible){
                let that = this;
                that.visible = visible;
            },

        },
    };
</script>
<style scoped>

</style>
