<template>
        <div>
            <a-drawer title="跟踪建议"  :visible="config.visible"  :width="700" :body-style="{ paddingBottom: '80px' }" @close="onClose">
               <div style="display: flex;justify-content: space-between;padding-bottom: 20px;">
                   <a-select v-model="region" placeholder="请选择" style="width: 200px">
                       <a-select-option value="shanghai">
                            待处理
                       </a-select-option>
                       <a-select-option value="beijing">
                           已处理
                       </a-select-option>
                   </a-select>
                   <div>
                       <a-icon type="setting" theme="filled" />
                       <label @click="onSetting">设置</label>
                   </div>
               </div>
                 <div style="background-color: rgb(239 239 239); border-radius:5px;display: flex;    justify-content: space-between;padding: 20px;margin-bottom: 10px;" v-for="item in 10">
                    <div style="display: flex">
                        <div style="padding: 5px">
                            <a-avatar size="large" icon="user" />
                        </div>
                        <div style="padding: 5px;display: flex;flex-direction: column;justify-content: space-between">
                            <div>旺旺:今天12:22</div>
                            <div>生日：客户今天生日，建议跟进并送出祝福</div>
                        </div>
                    </div>
                     <div>
                         <a-checkbox></a-checkbox>
                     </div>
                 </div>
                <a-pagination
                        show-size-changer
                        :default-current="3"
                        :total="500"
                        @showSizeChange="onShowSizeChange"
                />

            </a-drawer>
            <a-modal v-model="visibleSetting" title="跟踪提醒设置">

              <div>
                  <div>相关客户</div>
                  <div style="padding: 10px;">
                      <a-radio v-for="item in 2" style="padding: 10px;">我负责的全部客户</a-radio>
                  </div>
              </div>
              <div>
                  <div>跟踪建议项</div>
                  <div style="padding: 10px;display: flex;
    flex-wrap: wrap;">
                      <a-checkbox  v-for="item in 10" style="padding: 10px;">体验课</a-checkbox>
                  </div>
              </div>
            </a-modal>
        </div>
</template>

<script>
export default {
    props:{
        config: {
            type: Object,
            default: () => {
                return {
                    visible:false,
                }
            },
        },
    },
    data() {
        return {
            region:'',
            pageSize: 20,
            current: 4,
            visibleSetting:false,
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            form: {
                service: '',
                remark: '',
            },
        };
    },
    watch: {
        pageSize(val) {
            console.log('pageSize', val);
        },
        current(val) {
            console.log('current', val);
        },
    },
    methods: {

        onClose() {
            let that = this;
            that.config.visible = false;
        },
        onShowSizeChange(current, pageSize) {
            console.log(current, pageSize);
        },
        onSetting(){
            let that = this;
            that.visibleSetting = true
        }
    },
}
</script>

<style scoped>
    .ant-checkbox-wrapper + .ant-checkbox-wrapper{
        margin-left: 0px;
    }
    /deep/.ant-modal-footer{
        text-align: center;
    }
</style>