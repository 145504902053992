<template>
    <div>
        <a-modal v-model="config.visible" title="自动分配" @ok="handleOk"  @cancel="handleCancel" centered>
            <a-input-group compact>
                <div style="width: 40%;text-align: center;font-weight: 600">客户</div>
                <div style="width: 20%;text-align: center;font-weight: 600">分配</div>
                <div style="width: 40%;text-align: center;font-weight: 600">员工</div>
            </a-input-group>
            <a-input-group compact>
                <a-select
                        mode="multiple"
                        :default-value="['a1', 'b2']"
                        style="width: 40%"
                        placeholder="Please select"
                >
                    <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">
                        {{ (i + 9).toString(36) + i }}
                    </a-select-option>
                </a-select>
                <a-select default-value="Zhejiang">
                    <a-select-option value="Zhejiang">
                        平均分配
                    </a-select-option>
                    <a-select-option value="Jiangsu">
                        智能分配
                    </a-select-option>
                </a-select>
                <a-select
                        mode="multiple"
                        :default-value="['a1', 'b2']"
                        style="width: 40%"
                        placeholder="Please select"
                >
                    <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">
                        {{ (i + 9).toString(36) + i }}
                    </a-select-option>
                </a-select>
            </a-input-group>

        </a-modal>
    </div>
</template>

<script>
export default {
    props:{
        config: {
            type: Object,
            default: () => {
                return {
                    visible:false,
                }
            },
        },
    },
    data() {
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            form: {
                service: '',
                remark: '',
            },
        };
    },
    methods: {

        handleOk() {
            let that = this;
            that.config.visible = false;
        },
        handleCancel(){
            let that = this;
            that.config.visible = false;
        }
    },
}
</script>

<style scoped>
    /deep/.ant-modal-footer{
        text-align: center ;
    }
</style>